export default [
  {
    header: "My Pages",
  },
  {
    title: "News And Media",
    route: "apps-news-media",
    icon: "LayoutIcon",
    acl: {
      action: "read",
      resource: "ACL",
    },
  },
  {
    title: "Categories",
    route: "apps-categories",
    icon: "GridIcon",
    acl: {
      action: "read",
      resource: "ACL",
    },
  },
];
